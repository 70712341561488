@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: DINPro;
  src: url("../assets/fonts/DINPro-Regular.woff");
  font-weight: 400;
}
@font-face {
  font-family: DINPro;
  src: url("../assets/fonts/DINPro-Light.woff");
  font-weight: 300;
}
@font-face {
  font-family: DINPro;
  src: url("../assets/fonts/DINPro-Medium.woff");
  font-weight: 500;
}
@font-face {
  font-family: DINPro;
  src: url("../assets/fonts/DINPro-Bold.woff");
  font-weight: 600;
}
@font-face {
  font-family: DINPro;
  src: url("../assets/fonts/DINPro-Black.woff");
  font-weight: 800;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: DINPro;
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
  /* background-color: #133056; */
  color: black;
}

/* Navigation */
ul li {
  /* color: rgba(0, 0, 0, 0.4); */
  color: white;

  position: relative;
  z-index: 3;
}

ul li:hover {
  /* color: rgba(0, 0, 0); */
  color: white;
}

.blink,
.line {
  height: 20px;
  width: 2.5px;
  opacity: 0;
}

.open-button {
  height: 100%;
}

.open-button .line {
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.4);
}

ul li:hover .open-button .line {
  background-color: rgba(255, 255, 255, 1);
}

ul li:hover .blink,
.navigation-menu a:hover .blink {
  animation-name: blink;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  opacity: 1;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Hero */

.hero {
  transform: translateY(-68px);
}

.hero .wrapper > img {
  position: absolute;
  height: auto;
  width: 4%;

  animation-name: swing;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.hero > img {
  position: absolute;
  height: auto;
  width: 4%;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  transition: rotate(0deg);

  transition: 1s;
}

.wrapper {
  transition: 1s;
}

.hero .wrapper > img:first-child {
  width: 1.4%;
  left: 35%;
  top: 12%;
}

.hero .wrapper > img:nth-child(2) {
  top: 14%;
  right: 13%;
}

.hero .wrapper > img:nth-child(3) {
  left: 9.8%;
  top: 24%;
}

.hero .wrapper > img:nth-child(4) {
  top: 30.8%;
  right: 30.9%;
  animation-name: swingRotate;
}

.hero .wrapper > img:nth-child(5) {
  right: 23%;
  bottom: 30%;
}

.hero .wrapper > img:nth-child(6) {
  bottom: 22.1%;
  left: 16.7%;
  width: 2.5%;
}

.hero .wrapper > img:nth-child(7) {
  left: 28.2%;
  top: 51.6%;
}

@keyframes swing {
  0% {
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
  50% {
    transform: matrix(1, 0, 0, 1, 2, 2);
  }
  100% {
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
}

@keyframes swingRotate {
  0% {
    transform: rotate(90deg) matrix(1, 0, 0, 1, 0, 0);
  }
  50% {
    transform: rotate(90deg) matrix(1, 0, 0, 1, 2, 2);
  }
  100% {
    transform: rotate(90deg) matrix(1, 0, 0, 1, 0, 0);
  }
}

.logo {
  position: absolute;
  top: 50%;
  left: 50%;
  width: max-content;
  animation-name: swing2;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;

  transition: 1s;
}

@keyframes swing2 {
  0% {
    transform: translate(-50%, -50%) matrix(1, 0, 0, 1, 0, 0);
  }
  50% {
    transform: translate(-50%, -50%) matrix(1, 0, 0, 1, 2, 2);
  }
  100% {
    transform: translate(-50%, -50%) matrix(1, 0, 0, 1, 0, 0);
  }
}

.logo .img1 {
  width: 9vw;
  height: auto;
  margin-left: 4vw;
}

.logo .img2 {
  width: 9.1vw;
}

h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -55%);
  font-size: 9vw;
  line-height: 8.5vw;
  text-align: center;
  transition: opacity 1s, transform 1s;
}

h1 div {
  transition: 1s;
}

h1 .line1 {
  transform: rotate(-90deg);
}

h1 .line2 {
  transform: rotate(90deg);
}

h1 .blinkCursor {
  height: 11vw;
  width: 0.8vw;
  background-color: white;

  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  opacity: 0;
}

/* SkillCard */

.card {
  transform-style: preserve-3d;
}

.content {
  border-width: 12px;
}

.content:hover .card {
  transform: rotateY(180deg);
}

.front-card,
.back-card {
  backface-visibility: hidden;
}

.back-card {
  transform: rotateY(180deg);
}

/* input */

input,
textarea {
  color: black;
  background-color: #e5e5e5;
  outline: none;
  border-radius: 0px;
}

.bar {
  background-color: white;
}

.bar > div {
  background-color: black;
}
